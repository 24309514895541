import React from "react";
import Header from "../components/Header/Header";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../components/Footer/Footer";

const faqData = [
  {
    question: "UTTS’ ye Dahil Olan Mükellefler ve Paydaşlar kimlerdir?",
    answer:
      "Akaryakıt dağıtım şirketleri, akaryakıt istasyonu işleten mükellefler, YN Pompa ÖKC üreten veya ithal eden firmalar, Yetkili Taşıt Montaj Firmaları ve Yetkili İstasyon Montaj Firmaları ile bunların teknik servis bayileri,ve UTTS donanımlarını üreten veya ithal eden Yetkili Firmalar UTTS’ye kayıt yaptırmak suretiyle sistemde yer alacaklardır.",
  },
  {
    question: "UTTS’ ye Geçiş İçin Son Tarih Nedir?",
    answer:
      "UTTS mükelleflerinin en geç 31.12.2024 tarihine kadar UTTS'ye geçişlerini eksiksiz biçimde tamamlamaları zorunludur.",
  },
  {
    question: "UTTS Hangi Taşıtları Kapsamaktadır?",
    answer:
      "Kiralama yoluyla edinilen veya işletmeye dâhil olan ve işte kullanılan taşıtların akaryakıt tüketimlerinin gider olarak kaydedilebilmesi için bu taşıtlara TTB monte edilmiş olması ve taşıtların akaryakıt alımlarının UTTS üzerinden gerçekleştirilmesi zorunludur. Zorunluluk kapsamında olmayan taşıtlara da ihtiyari olarak TTB monte ettirilebilir. Taşıtın sahipliğinin, kullanım hakkının veya taşıtın plakasının değişmesi durumlarında, taşıta takılı olan TTB’nin taşıtın yeni sahibi veya işletmecisi tarafından değiştirilmesi gereklidir.",
  },
  {
    question: "UTTS donanımlarının montaj işlemleri nasıl yapılacak?",
    answer:
      "UTTS mükellefleri ve paydaşları Ulusal Taşıt Tanıma Sistemi’ne kaydolurlar. Taşıt sahipleri ve akaryakıt istasyonu işleten mükellefler UTTS donanımlarına ilişkin montaj taleplerini UTTS platformu üzerinden Darphane’ye bildirirler. Darphane tarafından yetkilendirilmiş olan Yetkili Montaj Firmaları Darphane’den temin edecekleri UTTS donanımlarını taşıtlara ve akaryakıt istasyonlarına monte ederler.",
  },
  {
    question:
      "UTTS kapsamında düzenlenmeyen akaryakıt satış belgeleri nasıl işlem görecek?",
    answer:
      "UTTS kapsamında yapılan akaryakıt satışlarında, taşıtın plakasına ilişkin bilgiler YN Pompa ÖKC’ye otomatik olarak iletilecek ve UTTS kapsamında düzenlendiğini gösteren bilgiyi ihtiva edecek şekilde ÖKC Fişi düzenlenecektir. 01.01.2025 tarihinden itibaren, TTB taktırma zorunluluğu getirilen taşıtların akaryakıt alımlarında UTTS kapsamında düzenlenmeyen belgeler 213 sayılı Kanunun 227’nci maddesine istinaden vergi kanunları bakımından hiç düzenlenmemiş sayılacaktır.",
  },
  {
    question: "Yetkili Taşıt Montaj Firmaları Kimlerdir?",
    answer:
      "Taşıtların yakıt depo girişine Taşıt Tanıma Birimi (TTB) takılması ve bunların UTTS’ye elektronik olarak kaydedilmesi hizmetlerini yürüten firmalardır. Bu firmalar Darphane tarafından onaylanan yetkili firmalardır. UTTS mükellefi taşıt sahipleri UTTS platformu üzerinden satın almış oldukları TTB’lerin taşıtlara montajı işlemleri sadece Yetkili Taşıt Montaj firmaları tarafından yürütülecektir.",
  },
  {
    question: "Vergi Mükellefi Taşıt Sahipleri kimlerdir?",
    answer:
      "Akaryakıt harcamalarını vergi beyanında gider olarak gösteren gerçek ve tüzel kişilikteki tüm taşıt sahipleridir. Araçlarına 31.12.2024 tarihine kadar Taşıt Tanıma Birimi (TTB) taktırmaları zorunludur.",
  },
  {
    question: "Taşıt Tanıma Birimi (TTB) birim fiyatlarını öğrenebilir miyim?",
    answer: `TTB birim fiyatları, Türkiye Cumhuriyeti Hazine ve Maliye Bakanlığı Darphane ve Damga Matbaası Genel Müdürlüğü tarafından belirlenmektedir. Filo kaydı ve ödeme işlemleri esnasında, siz değerli müşterilerimiz fiyat bilgilerini UTTS (Ulusal Taşıt Tanıma Sistemi) platformunda görebilirsiniz. www.utts.gov.tr\n\n Kit-tak olarak tüm müşterilerimize ücretsiz montaj hizmeti sunduğumuzu belirtmek isteriz. Araçlarınızın Darphane sistemine kaydını tamamladıktan sonra servis noktalarımızdan randevu alarak montaj işlemlerinizi ücretsiz olarak gerçekleştirebilirsiniz.`,
  },
];

const SıkSorulanSorular = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        mx={{ xs: 2, sm: 6, md: 6 }}
        my={4}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Header />
          <Box
            sx={{
              backgroundColor: "#002B32",
              borderBottomLeftRadius: "24px",
              borderBottomRightRadius: "24px",
              p: { xs: 3, sm: 5 },
            }}
          >
            <Box
              sx={{
                backgroundColor: "#F9FAFB",
                borderRadius: "12px",
                p: { xs: 3, sm: 5 },
              }}
            >
              <Box pt={isMobile ? 5 : 10}>
                <Typography
                  sx={{
                    color: "#006C51",
                    fontSize: { xs: 16, sm: 20 },
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  EN ÇOK MERAK EDİLENLER
                </Typography>
                <Typography
                  sx={{
                    py: 1,
                    color: "#000",
                    fontSize: { xs: 32, sm: 48 },
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Sıkça Sorulan Sorular
                </Typography>

                <Typography
                  mb={7}
                  sx={{
                    color: "#191C22",
                    fontSize: { xs: 16, sm: 18 },
                    fontWeight: 400,
                    textAlign: "center",
                    lineHeight: 1.6,
                  }}
                >
                  Bu bölümde, kullanıcılarımızdan en çok aldığımız sorulara ve
                  bu soruların yanıtlarına ulaşabilirsiniz.
                  <br />
                  Size daha iyi hizmet verebilmek adına en güncel bilgileri
                  burada bulabilirsiniz.
                </Typography>

                {/* FAQ Bölümü Başlangıcı */}
                <Box>
                  {faqData.map((faq, index) => (
                    <Accordion
                      key={index}
                      sx={{
                        backgroundColor: "#E6F3F0",
                        mb: 2,
                        borderRadius: 2,
                        boxShadow: "none",
                        "&:before": {
                          display: "none",
                        },
                        "& .MuiAccordionSummary-root": {
                          paddingY: { xs: 1, sm: 2 },
                        },
                        "& .MuiAccordionSummary-content": {
                          margin: 0,
                        },
                        "& .MuiTypography-root": {
                          fontSize: { xs: 18, sm: 24 },
                          fontWeight: 600,
                          color: "#008765",
                        },
                        "& .MuiAccordionDetails-root": {
                          paddingY: { xs: 1, sm: 1.5 },
                        },
                        "& .MuiTypography-body1": {
                          fontSize: { xs: 14, sm: 18 },
                          fontWeight: 400,
                          color: "#000",
                          lineHeight: 1.6,
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Typography
                          sx={{
                            color: "#008765 !important",
                            fontWeight: "600 !important",
                          }}
                        >
                          {faq.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography sx={{ whiteSpace: "pre-wrap" }}>
                          {faq.answer}
                        </Typography>
                        {index === 7 && (
                          <Typography mt={2}>
                            <a
                              href="https://www.utts.gov.tr/guidelines/utts-tasit-sahibi-mukellef-fiyat-listesi.pdf"
                              target="_blank"
                              rel="noreferrer"
                              style={{ color: "#000", fontWeight: "bold" }}
                            >
                              Taşıt Sahibi Mükellef -2024 Yılı Birim Fiyat
                              Listesi
                            </a>
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Box>
                {/* FAQ Bölümü Bitişi */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mx={{ xs: 2, sm: 6, md: 6 }} mb={2}>
        <Footer />
      </Box>
    </>
  );
};

export default SıkSorulanSorular;
