import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NearMeIcon from "@mui/icons-material/NearMe";
import Check from "@mui/icons-material/CheckCircle";
import UploadCloud from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronDown from "@mui/icons-material/ExpandMore";
import { State, City, IState, ICity } from "country-state-city";
import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import ReactGA from "react-ga4";

const NedenKitTakOrtak = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = useState(0);

  const [cities, setCities] = useState<IState[]>([]);
  const [districts, setDistricts] = useState<ICity[]>([]);
  const [selectedCity, setSelectedCity] = useState<IState | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<ICity | null>(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 40.98691,
    lng: 28.76995,
  });
  const [mapLoaded, setMapLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const locations = [
    {
      lat: 40.98691,
      lng: 28.76995,
    },
  ];

  const handleLoad = () => {
    setTimeout(() => {
      setMapLoaded(true);
      setLoadError(false);
    }, 500);
  };

  const handleError = () => {
    setLoadError(true);
    setMapLoaded(false);
  };

  useEffect(() => {
    const citiesData = State.getStatesOfCountry("TR");
    setCities(citiesData);
  }, []);

  useEffect(() => {
    if (selectedCity) {
      const districtsData = City.getCitiesOfState("TR", selectedCity.isoCode);
      setDistricts(districtsData);
    }
  }, [selectedCity]);

  // Form states
  const [basvuruAlindi, setBasvuruAlindi] = useState(false);

  // Additional states
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // FormData nesnesi oluştur
    const formData = new FormData();

    try {
      // Yetkili Bilgileri
      formData.append("yetkiliIsmi", yetkiliIsmi);
      formData.append("dogumTarihi", dogumTarihi);
      formData.append("tcKimlikNo", tcKimlikNo);
      formData.append("yetkiliMailAdresi", yetkiliMailAdresi);
      formData.append("yetkiliTelefonNo", yetkiliTelefonNo);

      // Firma Bilgileri
      formData.append("firmaAdi", firmaAdi);
      formData.append("resmiUnvan", resmiUnvan);
      formData.append("vergiDairesi", vergiDairesi);
      formData.append("vergiNo", vergiNo);
      formData.append("firmaWebSitesi", firmaWebSitesi);
      formData.append("eFaturaMukellefi", eFaturaMukellefi ? "true" : "false");

      // Firma Adres Bilgileri
      formData.append("firmaIl", firmaIl);
      formData.append("firmaIlce", firmaIlce);
      formData.append("postaKodu", postaKodu);
      formData.append("adres", adres);

      // Gerekli Bilgiler (File Upload)
      uploadedFiles.forEach((file) => {
        formData.append("uploadedFiles", file);
      });

      // Montaj Noktasına Ait Bilgiler
      formData.append("montajTabelaIsmi", montajTabelaIsmi);
      formData.append("montajVergiNo", montajVergiNo);
      formData.append("montajVergiDairesi", montajVergiDairesi);
      formData.append("montajIl", montajIl);
      formData.append("montajIlce", montajIlce);
      formData.append("montajMahalle", montajMahalle);
      formData.append("montajAdres", montajAdres);
      formData.append("enlem", enlem);
      formData.append("boylam", boylam);

      // İşletmeye ait bilgiler
      formData.append("isletmeTipi", isletmeTipi);
      formData.append("isyeriTipi", isyeriTipi);
      formData.append("toplamCalisanSayisi", toplamCalisanSayisi);
      formData.append("montajCalisanSayisi", montajCalisanSayisi);
      formData.append("calismaGunleri", calismaGunleri);
      formData.append("toplamAracKapasitesi", toplamAracKapasitesi);
      formData.append("aracTipleri", aracTipleri);
      formData.append("mobilMontajHizmeti", mobilMontajHizmeti);
      formData.append("internetHizmeti", internetHizmeti);

      // Onaylar
      formData.append(
        "sartlariKabulEdiyorum",
        sartlariKabulEdiyorum ? "true" : "false"
      );
      formData.append("kvkkOnay", kvkkOnay ? "true" : "false");

      // Başvuru Tipi
      formData.append("basvuruTipi", "Montaj Basvurusu");

      // API çağrısı
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/bayii-basvuru/basvuru`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        setBasvuruAlindi(true);
        ReactGA.send({
          hitType: "event",
          eventCategory: "Conversion",
          eventAction: "form_submission",
          eventLabel: "Bayii Montaj Basvuru",
          value: 1,
        });
      } else {
        setError("Başvuru gönderilirken beklenmedik bir hata oluştu.");
      }
    } catch (err: any) {
      console.error("API Hatası:", err);
      setError(
        err.response?.data?.message || "Başvuru gönderilirken bir hata oluştu."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
          );
          const data = await response.json();
          if (data.status === "OK") {
            setMapCenter({
              lat: latitude,
              lng: longitude,
            });
          } else {
            alert("Adres bulunamadı");
          }
        },
        (error) => {
          console.error(error);
          alert("Konum alınırken hata oluştu");
        }
      );
    } else {
      alert("Tarayıcınız konum hizmetlerini desteklemiyor.");
    }
  };

  const [basvuruSart1, setBasvuruSart1] = useState(false);
  const [basvuruSart2, setBasvuruSart2] = useState(false);

  // Form field states for Case 3
  // Yetkili Bilgileri
  const [yetkiliIsmi, setYetkiliIsmi] = useState("");
  const [dogumTarihi, setDogumTarihi] = useState("");
  const [tcKimlikNo, setTcKimlikNo] = useState("");
  const [yetkiliMailAdresi, setYetkiliMailAdresi] = useState("");
  const [yetkiliTelefonNo, setYetkiliTelefonNo] = useState("");
  // Firma Bilgileri
  const [firmaAdi, setFirmaAdi] = useState("");
  const [resmiUnvan, setResmiUnvan] = useState("");
  const [vergiDairesi, setVergiDairesi] = useState("");
  const [vergiNo, setVergiNo] = useState("");
  const [firmaWebSitesi, setFirmaWebSitesi] = useState("");
  const [eFaturaMukellefi, setEFaturaMukellefi] = useState(false);
  // Firma Adres Bilgileri
  const [firmaIl, setFirmaIl] = useState("");
  const [firmaIlce, setFirmaIlce] = useState("");
  const [firmaDistricts, setFirmaDistricts] = useState<ICity[]>([]);
  const [postaKodu, setPostaKodu] = useState("");
  const [adres, setAdres] = useState("");
  // Gerekli Bilgiler (File Upload)
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  // Montaj Noktasına Ait Bilgiler
  const [montajTabelaIsmi, setMontajTabelaIsmi] = useState("");
  const [montajVergiNo, setMontajVergiNo] = useState("");
  const [montajVergiDairesi, setMontajVergiDairesi] = useState("");
  const [montajIl, setMontajIl] = useState("");
  const [montajIlce, setMontajIlce] = useState("");
  const [montajDistricts, setMontajDistricts] = useState<ICity[]>([]);
  const [montajMahalle, setMontajMahalle] = useState("");
  const [montajAdres, setMontajAdres] = useState("");
  const [enlem, setEnlem] = useState("");
  const [boylam, setBoylam] = useState("");
  // İşletmeye ait bilgiler
  const [isletmeTipi, setIsletmeTipi] = useState("");
  const [isyeriTipi, setIsyeriTipi] = useState("");
  const [toplamCalisanSayisi, setToplamCalisanSayisi] = useState("");
  const [montajCalisanSayisi, setMontajCalisanSayisi] = useState("");
  const [calismaGunleri, setCalismaGunleri] = useState("");
  const [toplamAracKapasitesi, setToplamAracKapasitesi] = useState("");
  const [aracTipleri, setAracTipleri] = useState("");
  const [mobilMontajHizmeti, setMobilMontajHizmeti] = useState("Evet");
  const [internetHizmeti, setInternetHizmeti] = useState("Evet");
  // Checkboxes
  const [sartlariKabulEdiyorum, setSartlariKabulEdiyorum] = useState(false);
  const [kvkkOnay, setKvkkOnay] = useState(false);

  // Update Firma Districts when Firma Il changes
  useEffect(() => {
    if (firmaIl) {
      const districtsData = City.getCitiesOfState("TR", firmaIl);
      setFirmaDistricts(districtsData);
    }
  }, [firmaIl]);

  // Update Montaj Districts when Montaj Il changes
  useEffect(() => {
    if (montajIl) {
      const districtsData = City.getCitiesOfState("TR", montajIl);
      setMontajDistricts(districtsData);
    }
  }, [montajIl]);

  const renderSteps = useMemo(() => {
    const stepsData = [
      {
        title:
          "Siz de Yetkili Montaj Servisi Olarak Büyüyen Bir Sektörde Güvenilir İlave Gelir Fırsatları Yakalayın!",
        description: `Siz de Yetkili Montaj Servisi Olarak Büyüyen Bir Sektörde Güvenilir İlave Gelir Fırsatları Yakalayın!

Türkiye Cumhuriyeti Hazine ve Maliye Bakanlığı Darphane ve Damga Matbaası Genel Müdürlüğü tarafından yönetilen Ulusal Taşıt Tanıma Sistemi (UTTS), Türkiye’de araç yönetimi ve taşıt tanıma alanında yenilikçi bir iş kolu olarak hayata geçirilmiştir.

Kit-tak platformu ile iş birliği içinde çalışan yetkili montaj servisleri, güvenilir ve düzenli bir iş ve gelir akışına sahip olmanın yanında, TTB montajı ve diğer bağlantılı hizmetlerle çeşitlendirilmiş yeni gelir imkanlarına erişim sağlamaktadır.
`,
        image: require("../assets/img/kittak1.png"),
      },
    ];

    const currentData = stepsData[0];

    switch (currentStep) {
      case 0:
        return (
          <Grid container spacing={4} mt={4}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    xs: 20,
                    sm: 24,
                  },
                  color: "#008765",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: 2,
                }}
              >
                Kit-Tak Avantajları
              </Typography>
              <Box
                px={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      sm: 16,
                    },
                    color: "#191C22",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  İş Yeriniz İçin Güvenli İlave Gelir Yaratın! Türkiye’nin
                  Yetkili TTB Montaj Servisi Ağına Katılın ve<br></br>
                  Gelirlerinizi Güvence Altına Alın!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#E6F3F0",
                  borderRadius: "8px",
                  height: "100%",
                  p: {
                    xs: 2,
                    sm: 3,
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: 20,
                      sm: 28,
                    },
                    color: "#000",
                    fontWeight: "600",
                    mt: 1,
                  }}
                >
                  {currentData.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      sm: 16,
                    },
                    color: "#1C1B1C",
                    fontWeight: "400",
                    mt: 2,
                    whiteSpace: "pre-line",
                  }}
                >
                  {currentData.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  height: "100%",
                  p: {
                    xs: 2,
                    sm: 3,
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={currentData.image}
                  alt="UTTS"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <>
            <Grid
              container
              gap={2}
              sx={{
                backgroundColor: "#002B32",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
                mt: 6,
                py: 2,
                pt: 4,
                width: "100%",
              }}
            >
              <Grid item xs={12} md={3} px={1}>
                <FormControl fullWidth>
                  <InputLabel>İl seçin</InputLabel>
                  <Select
                    value={selectedCity?.isoCode || ""}
                    sx={{
                      backgroundColor: "#f3f3f3",
                      borderRadius: 2,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      const selectedCity = cities.find(
                        (city) => city.isoCode === e.target.value
                      );
                      if (selectedCity) {
                        setSelectedCity(selectedCity);
                      }
                    }}
                    fullWidth
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.isoCode} value={city.isoCode}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} px={1}>
                <FormControl fullWidth>
                  <InputLabel>İlçe seçin</InputLabel>
                  <Select
                    value={selectedDistrict?.name || ""}
                    sx={{
                      backgroundColor: "#f3f3f3",
                      borderRadius: 2,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                    variant="outlined"
                    placeholder="İlçe seçin"
                    onChange={(e) => {
                      const selectedDistrict = districts.find(
                        (ilce) => ilce.name === e.target.value
                      );
                      if (selectedDistrict) {
                        setSelectedDistrict(selectedDistrict);
                      }
                    }}
                    fullWidth
                  >
                    {districts.map((district) => (
                      <MenuItem key={district.name} value={district.name}>
                        {district.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3} px={1}>
                <Button
                  onClick={handleGetLocation}
                  fullWidth
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    px: 2,
                    alignItems: "center",
                    gap: 1,
                    height: 58,
                    color: "#002B32",
                    backgroundColor: "#f3f3f3",
                    borderRadius: 2,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                >
                  Konumu Otomatik Al{" "}
                  <Box
                    sx={{
                      pt: 1,
                      cursor: "pointer",
                    }}
                  >
                    <NearMeIcon />
                  </Box>
                </Button>
              </Grid>

              <Grid item height={58} xs={12} md={2} px={1}>
                <Box
                  sx={{
                    backgroundColor: "#002b32",
                    color: "#fff",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "32px",
                    border: "1px solid #36BFFA",
                    cursor: "pointer",
                  }}
                  onClick={() => setCurrentStep(currentStep + 1)}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontSize: 16,
                    }}
                  >
                    Başvuruya Devam Et
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              height={600}
              sx={{
                backgroundColor: "#002B32",
                p: 2,
              }}
            >
              <LoadScript
                onLoad={handleLoad}
                onError={handleError}
                googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
              >
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={mapCenter}
                  zoom={12}
                  onLoad={() => {
                    setTimeout(() => {
                      setMapLoaded(true);
                    }, 1500);
                  }}
                >
                  {loadError ? (
                    <Typography color="error">
                      Harita yüklenirken bir hata oluştu, lütfen tekrar deneyin.
                    </Typography>
                  ) : (
                    !mapLoaded && <Typography>Harita Yükleniyor...</Typography>
                  )}
                  {mapLoaded &&
                    locations.map((location, index) => (
                      <React.Fragment key={index}>
                        <Marker
                          position={{
                            lat: location.lat,
                            lng: location.lng,
                          }}
                          icon={{
                            strokeColor: "#173D00",
                            url: require("../assets/img/takkitmarker.png"),
                            scaledSize: new window.google.maps.Size(40, 40),
                          }}
                        />
                        <Circle
                          center={{
                            lat: location.lat,
                            lng: location.lng,
                          }}
                          radius={2000}
                          options={{
                            fillColor: "#173D00",
                            fillOpacity: 0.2,
                            strokeColor: "#173D00",
                            strokeOpacity: 1,
                            strokeWeight: 1,
                          }}
                        />
                      </React.Fragment>
                    ))}
                </GoogleMap>
              </LoadScript>
            </Grid>
          </>
        );
      case 2:
        return (
          <Box
            display="flex"
            flexDirection={{
              xs: "column-reverse",
              md: "row",
            }}
            justifyContent="center"
            alignItems="flex-start"
            gap={4}
            p={{
              xs: 1,
              md: 4,
            }}
            bgcolor="white"
            borderRadius={2}
            overflow="hidden"
          >
            <Grid
              container
              spacing={{
                xs: 2,
                sm: 5,
              }}
              flexDirection={{
                xs: "column-reverse",
                md: "row",
              }}
            >
              <Grid item xs={12} md={7}>
                <Box
                  sx={{
                    margin: "0 auto",
                    p: {
                      xs: 2,
                      sm: 4,
                    },
                    bgcolor: "background.default",
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                >
                  <Stack spacing={3}>
                    {/* Header Section */}
                    <Stack spacing={2}>
                      <Typography variant="h5" color="text.primary">
                        Beyan ve Gerekli Bilgiler
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        UTTS kapsamında taşıtlara Taşıt Tanıma Birimi (TTB)
                        montaj işlemleri sadece ‘’Yetkilendirilmiş Taşıt Montaj
                        Firmaları’’ ve Yetkili TTB Montaj Servisleri tarafından
                        yapılabilir. Taşıt Tanıma Birimi montaj hizmeti
                        verebilmek için sadece aşağıdaki şartları sağlayan
                        firmalar, başvuru yapabilecektir. Belirtilen şartları
                        taşımayan firmaların başvuruları değerlendirilmeye
                        alınmayacaktır. Başvuru yapan firmanın bilgi ve
                        belgeleri Darphane ve Damga Matbaası Genel Müdürlüğü
                        tarafından kontrol edilecektir.
                      </Typography>
                    </Stack>

                    {/* Acceptance Statement */}
                    <Typography variant="h6" color="text.primary">
                      Aşağıdaki şartları sağladığınızı kabul ederek başvuru
                      formunu doldurmaya başlayabilirsiniz.
                    </Typography>

                    {/* Faaliyet Gösterilen Sektör */}
                    <Stack spacing={1}>
                      <Typography variant="subtitle1" color="text.primary">
                        Faaliyet Gösterilen Sektör
                      </Typography>
                      <List disablePadding>
                        {[
                          "Motorlu taşıt markasına ait yetkili bakım, onarım ve/veya teknik servis",
                          "Motorlu taşıtlarla ilgili özel bakım, montaj, onarım ve/veya teknik servis",
                          "Taşıt tanıma ve/veya taşıt takip sistemleri montaj servisi",
                        ].map((text, index) => (
                          <ListItem key={index} disableGutters>
                            <Box
                              sx={{
                                width: 8,
                                height: 8,
                                bgcolor: "#002b32",
                                borderRadius: "50%",
                                mr: 1.5,
                                mt: 0.5,
                              }}
                            />
                            <ListItemText
                              primary={text}
                              primaryTypographyProps={{
                                variant: "body1",
                                color: "text.secondary",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Stack>

                    {/* İş Yeri Büyüklüğü */}
                    <Stack spacing={1}>
                      <Typography variant="subtitle1" color="text.primary">
                        İş Yeri Büyüklüğü (Montaj Alanı)
                      </Typography>
                      <List disablePadding>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="En az 4 aracın aynı anda bulunabileceği açık/kapalı alan"
                            primaryTypographyProps={{
                              variant: "body1",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <Checkbox
                            checked={basvuruSart1}
                            onChange={(e) => setBasvuruSart1(e.target.checked)}
                          />
                          <ListItemText
                            primary="Evet"
                            primaryTypographyProps={{
                              variant: "body2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Stack>

                    {/* Montaj Personeli */}
                    <Stack spacing={1}>
                      <Typography variant="subtitle1" color="text.primary">
                        Montaj Personeli
                      </Typography>
                      <List disablePadding>
                        <ListItem disableGutters>
                          <ListItemText
                            primary="Motorlu taşıtlara el aletleri kullanarak montaj yapabilen en az 2 personele sahibim"
                            primaryTypographyProps={{
                              variant: "body1",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <Checkbox
                            checked={basvuruSart2}
                            onChange={(e) => setBasvuruSart2(e.target.checked)}
                          />
                          <ListItemText
                            primary="Evet"
                            primaryTypographyProps={{
                              variant: "body2",
                              color: "text.secondary",
                            }}
                          />
                        </ListItem>
                      </List>
                    </Stack>

                    {/* Submit Button */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={() => {
                          if (basvuruSart1 && basvuruSart2) {
                            setCurrentStep(currentStep + 1);
                          } else {
                            alert("Lütfen tüm şartları kabul edin.");
                          }
                        }}
                        variant="contained"
                        sx={{
                          bgcolor: "#002b32",
                          borderRadius: "32px",
                          px: {
                            xs: 4,
                            sm: 7,
                          },
                          py: {
                            xs: 1.5,
                            sm: 2,
                          },
                          boxShadow: 1,
                          "&:hover": {
                            bgcolor: "#001a20",
                          },
                        }}
                      >
                        <Typography variant="button" color="white">
                          Başvuruda Bulun
                        </Typography>
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={{
                    xs: 4,
                    md: 0,
                  }}
                  width={{
                    xs: "100%",
                    md: "100%",
                  }}
                >
                  <img
                    width="100%"
                    src={require("../assets/img/is.png")}
                    alt="Montaj Noktaları"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      case 3:
        return (
          <Grid container spacing={4} mt={4}>
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={4}
                p={{
                  xs: 2,
                  md: 4,
                }}
                bgcolor="white"
                borderRadius={2}
                overflow="hidden"
              >
                {basvuruAlindi ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                    mt={{
                      xs: 4,
                      sm: 8,
                    }}
                  >
                    <img
                      src={require("../assets/img/basvuru-alindi.png")}
                      alt="Başvuru Alındı"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <Typography
                      variant="h4"
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        mt: 2,
                        fontSize: {
                          xs: 24,
                          sm: 32,
                        },
                        lineHeight: {
                          xs: "32px",
                          sm: "40px",
                        },
                      }}
                    >
                      Randevu Talebiniz Alınmıştır
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: "center",
                        mt: 2,
                        fontSize: {
                          xs: 16,
                          sm: 18,
                        },
                        lineHeight: {
                          xs: "24px",
                          sm: "28px",
                        },
                      }}
                    >
                      Yetkili müşteri temsilcimiz sizinle en kısa zamanda
                      iletişime geçecektir.
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "1200px",
                      p: {
                        xs: 2,
                        sm: 4,
                      },
                      bgcolor: "white",
                      borderRadius: "18.41px",
                    }}
                  >
                    {/* Replace with your new form */}
                    <form onSubmit={handleSubmit}>
                      {/* Yetkili Bilgileri */}
                      <Box
                        sx={{
                          width: "100%",
                          mb: 4,
                        }}
                      >
                        <Typography variant="h6" component="div">
                          Yetkili Bilgileri
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Başvuru Yapan Firma Adına İşlem Yapma Yetkisine Sahip
                          Kullanıcı Bilgileri
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: 2,
                          }}
                        >
                          {/* Form Fields */}
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Yetkili İsmi"
                              placeholder="İsim Soyisim"
                              variant="outlined"
                              value={yetkiliIsmi}
                              onChange={(e) => setYetkiliIsmi(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Doğum Tarihi"
                              placeholder="11-11-11"
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={dogumTarihi}
                              onChange={(e) => setDogumTarihi(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="TC Kimlik Numarası"
                              placeholder="----------"
                              variant="outlined"
                              value={tcKimlikNo}
                              onChange={(e) => setTcKimlikNo(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Yetkili Mail Adresi"
                              placeholder="email@example.com"
                              variant="outlined"
                              value={yetkiliMailAdresi}
                              onChange={(e) =>
                                setYetkiliMailAdresi(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Yetkili Telefon Numarası"
                              placeholder="0 (555) 000-0000"
                              variant="outlined"
                              value={yetkiliTelefonNo}
                              onChange={(e) =>
                                setYetkiliTelefonNo(e.target.value)
                              }
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* Firma Bilgileri */}
                      <Box
                        sx={{
                          width: "100%",
                          mt: 4,
                        }}
                      >
                        <Typography variant="h6" component="div">
                          Firma Bilgileri
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Firma Adı"
                              placeholder="Firma Adı"
                              variant="outlined"
                              value={firmaAdi}
                              onChange={(e) => setFirmaAdi(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Resmi Ünvan"
                              placeholder="Resmi Ünvan"
                              variant="outlined"
                              value={resmiUnvan}
                              onChange={(e) => setResmiUnvan(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Vergi Dairesi"
                              placeholder="Vergi Dairesi"
                              variant="outlined"
                              value={vergiDairesi}
                              onChange={(e) => setVergiDairesi(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Vergi No"
                              placeholder="Vergi No"
                              variant="outlined"
                              value={vergiNo}
                              onChange={(e) => setVergiNo(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Firma Web Sitesi"
                              placeholder="www.example.com"
                              variant="outlined"
                              value={firmaWebSitesi}
                              onChange={(e) =>
                                setFirmaWebSitesi(e.target.value)
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eFaturaMukellefi}
                                  onChange={(e) =>
                                    setEFaturaMukellefi(e.target.checked)
                                  }
                                />
                              }
                              label="E-Fatura Vergi Mükellefiyim"
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* Firma Adres Bilgileri */}
                      <Box
                        sx={{
                          width: "100%",
                          mt: 4,
                        }}
                      >
                        <Typography variant="h6" component="div">
                          Firma Adres Bilgileri
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" required>
                              <InputLabel>İl</InputLabel>
                              <Select
                                label="İl"
                                value={firmaIl}
                                onChange={(e) => setFirmaIl(e.target.value)}
                              >
                                {cities.map((city) => (
                                  <MenuItem
                                    key={city.isoCode}
                                    value={city.isoCode}
                                  >
                                    {city.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" required>
                              <InputLabel>İlçe</InputLabel>
                              <Select
                                label="İlçe"
                                value={firmaIlce}
                                onChange={(e) => setFirmaIlce(e.target.value)}
                              >
                                {firmaDistricts.map((district) => (
                                  <MenuItem
                                    key={district.name}
                                    value={district.name}
                                  >
                                    {district.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Posta Kodu"
                              variant="outlined"
                              value={postaKodu}
                              onChange={(e) => setPostaKodu(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Adres"
                              variant="outlined"
                              multiline
                              rows={4}
                              value={adres}
                              onChange={(e) => setAdres(e.target.value)}
                              required
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* Gerekli Bilgiler */}
                      <Box
                        sx={{
                          width: "100%",
                          mb: 4,
                          mt: 4,
                        }}
                      >
                        <Typography variant="h6" component="div">
                          Gerekli Bilgiler
                        </Typography>
                        {/* List of documents */}
                        <List>
                          <ListItem>
                            <ListItemText primary="Vergi Levhası" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="İş yeri Ruhsatı" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Ticaret Sicil Gazetesi" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Kayıtlı Olduğu Odadan Faaliyet Belgesi" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="İmza Sirküleri" />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="İş Yerini Ön cepheden gösteren ve En az 4 aracın aynı anda bulunabileceği açık/kapalı alanın varlığını gösteren fotoğraflar" />
                          </ListItem>
                        </List>
                        <Box
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            startIcon={<UploadCloud />}
                            sx={{
                              width: "100%",
                              py: 2,
                            }}
                            component="label"
                          >
                            Belge ekle
                            <input
                              type="file"
                              hidden
                              multiple
                              onChange={(e) => {
                                if (e.target.files) {
                                  setUploadedFiles([
                                    ...uploadedFiles,
                                    ...Array.from(e.target.files),
                                  ]);
                                }
                              }}
                            />
                          </Button>
                          <Typography
                            variant="caption"
                            display="block"
                            align="center"
                            sx={{
                              mt: 1,
                            }}
                          >
                            pdf-png-jpg-jpeg formatında. Max 10 MB
                          </Typography>
                        </Box>
                        {/* Uploaded files */}
                        {uploadedFiles.map((file, index) => (
                          <Box
                            key={index}
                            sx={{
                              mt: 2,
                              p: 2,
                              bgcolor: "white",
                              border: "1px solid",
                              borderColor: "grey.300",
                              borderRadius: 1,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Typography variant="body2">
                                {file.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {(file.size / 1024 / 1024).toFixed(2)} MB
                              </Typography>
                            </Box>
                            <IconButton
                              onClick={() => {
                                setUploadedFiles(
                                  uploadedFiles.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>

                      {/* Montaj Noktasına Ait Bilgiler */}
                      <Box
                        sx={{
                          width: "100%",
                          mt: 4,
                        }}
                      >
                        <Typography variant="h6" component="div">
                          Montaj Noktasına Ait Bilgiler
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Montaj Noktası Tabela İsmi *"
                              placeholder=""
                              variant="outlined"
                              value={montajTabelaIsmi}
                              onChange={(e) =>
                                setMontajTabelaIsmi(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Vergi Numarası *"
                              placeholder=""
                              variant="outlined"
                              value={montajVergiNo}
                              onChange={(e) => setMontajVergiNo(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Vergi Dairesi*"
                              placeholder=""
                              variant="outlined"
                              value={montajVergiDairesi}
                              onChange={(e) =>
                                setMontajVergiDairesi(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" required>
                              <InputLabel>İl*</InputLabel>
                              <Select
                                label="İl*"
                                value={montajIl}
                                onChange={(e) => setMontajIl(e.target.value)}
                              >
                                {cities.map((city) => (
                                  <MenuItem
                                    key={city.isoCode}
                                    value={city.isoCode}
                                  >
                                    {city.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth variant="outlined" required>
                              <InputLabel>İlçe*</InputLabel>
                              <Select
                                label="İlçe*"
                                value={montajIlce}
                                onChange={(e) => setMontajIlce(e.target.value)}
                              >
                                {montajDistricts.map((district) => (
                                  <MenuItem
                                    key={district.name}
                                    value={district.name}
                                  >
                                    {district.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* Mahalle can be a TextField */}
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Mahalle*"
                              placeholder=""
                              variant="outlined"
                              value={montajMahalle}
                              onChange={(e) => setMontajMahalle(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Adres*"
                              variant="outlined"
                              multiline
                              rows={4}
                              value={montajAdres}
                              onChange={(e) => setMontajAdres(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Enlem"
                              variant="outlined"
                              value={enlem}
                              onChange={(e) => setEnlem(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Boylam"
                              variant="outlined"
                              value={boylam}
                              onChange={(e) => setBoylam(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      {/* İşletmeye ait bilgiler */}
                      <Box
                        sx={{
                          width: "100%",
                          mt: 4,
                        }}
                      >
                        <Typography variant="h6" component="div">
                          İşletmeye ait bilgiler
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            mt: 2,
                          }}
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="İşletme Tipi/ Montaj Hizmetleri Sağlayıcı Tipi*"
                              placeholder=""
                              variant="outlined"
                              value={isletmeTipi}
                              onChange={(e) => setIsletmeTipi(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="İşyeri Tipi*"
                              placeholder=""
                              variant="outlined"
                              value={isyeriTipi}
                              onChange={(e) => setIsyeriTipi(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Toplam Çalışan Sayısı*"
                              placeholder=""
                              variant="outlined"
                              value={toplamCalisanSayisi}
                              onChange={(e) =>
                                setToplamCalisanSayisi(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Montaj Hizmeti Verebilecek Çalışan Sayısı*"
                              placeholder=""
                              variant="outlined"
                              value={montajCalisanSayisi}
                              onChange={(e) =>
                                setMontajCalisanSayisi(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Çalışma Günleri*"
                              placeholder=""
                              variant="outlined"
                              value={calismaGunleri}
                              onChange={(e) =>
                                setCalismaGunleri(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Toplam Araç Kapasitesi*"
                              placeholder=""
                              variant="outlined"
                              value={toplamAracKapasitesi}
                              onChange={(e) =>
                                setToplamAracKapasitesi(e.target.value)
                              }
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Araç Tipleri/ Hizmet Verebilecek Taşıt Türü"
                              placeholder=""
                              variant="outlined"
                              value={aracTipleri}
                              onChange={(e) => setAracTipleri(e.target.value)}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" required>
                              <InputLabel>
                                Mobil Montaj Hizmeti sağlayabiliyor mu?
                              </InputLabel>
                              <Select
                                label="Mobil Montaj Hizmeti sağlayabiliyor mu?"
                                value={mobilMontajHizmeti}
                                onChange={(e) =>
                                  setMobilMontajHizmeti(e.target.value)
                                }
                              >
                                <MenuItem value="Evet">Evet</MenuItem>
                                <MenuItem value="Hayır">Hayır</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" required>
                              <InputLabel>
                                İnternet hizmeti mevcut mu?
                              </InputLabel>
                              <Select
                                label="İnternet hizmeti mevcut mu?"
                                value={internetHizmeti}
                                onChange={(e) =>
                                  setInternetHizmeti(e.target.value)
                                }
                              >
                                <MenuItem value="Evet">Evet</MenuItem>
                                <MenuItem value="Hayır">Hayır</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>

                      {/* Checkboxes */}
                      <Box
                        sx={{
                          width: "100%",
                          mt: 4,
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={sartlariKabulEdiyorum}
                              onChange={(e) =>
                                setSartlariKabulEdiyorum(e.target.checked)
                              }
                            />
                          }
                          label="Yukarıdaki şartları sağladığımı, ilgili bilgilerin doğru olduğunu ve başvurumun kabul edilmesi halinde bunu teyit eden evrakları temin edeceğimi beyan ederim."
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={kvkkOnay}
                              onChange={(e) => setKvkkOnay(e.target.checked)}
                            />
                          }
                          label={
                            <>
                              <span>KVKK yürürlüğündeki </span>
                              <span
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  window.open("/gizlilik", "_blank")
                                }
                              >
                                metni
                              </span>
                              <span> okudum ve onaylıyorum.</span>
                            </>
                          }
                        />
                      </Box>

                      {/* Submit Button */}
                      <Box
                        sx={{
                          mt: 4,
                          textAlign: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{
                            px: 7,
                            py: 2,
                            borderRadius: "64px",
                            bgcolor: "primary.600",
                            color: "white",
                            textTransform: "none",
                          }}
                          disabled={
                            !sartlariKabulEdiyorum || !kvkkOnay || loading
                          }
                        >
                          {loading ? "Gönderiliyor..." : "Başvuruyu tamamla"}
                        </Button>
                      </Box>
                    </form>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={{
                  xs: 4,
                  md: 0,
                }}
                width={{
                  xs: "100%",
                  md: "100%",
                }}
              >
                <img
                  width="100%"
                  src={require("../assets/img/is.png")}
                  alt="Montaj Noktaları"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  }, [
    currentStep,
    selectedCity,
    selectedDistrict,
    cities,
    districts,
    mapCenter,
    basvuruSart1,
    basvuruSart2,
    basvuruAlindi,
    loading,
    mapLoaded,
    loadError,
    // Added dependencies
    yetkiliIsmi,
    dogumTarihi,
    tcKimlikNo,
    yetkiliMailAdresi,
    yetkiliTelefonNo,
    firmaAdi,
    resmiUnvan,
    vergiDairesi,
    vergiNo,
    firmaWebSitesi,
    eFaturaMukellefi,
    firmaIl,
    firmaIlce,
    firmaDistricts,
    postaKodu,
    adres,
    uploadedFiles,
    montajTabelaIsmi,
    montajVergiNo,
    montajVergiDairesi,
    montajIl,
    montajIlce,
    montajDistricts,
    montajMahalle,
    montajAdres,
    enlem,
    boylam,
    isletmeTipi,
    isyeriTipi,
    toplamCalisanSayisi,
    montajCalisanSayisi,
    calismaGunleri,
    toplamAracKapasitesi,
    aracTipleri,
    mobilMontajHizmeti,
    internetHizmeti,
    sartlariKabulEdiyorum,
    kvkkOnay,
  ]);

  return (
    <Box
      mx={{
        xs: 2,
        sm: 4,
        md: 6,
      }}
      my={4}
    >
      <Header />
      <Box
        py={3}
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
        }}
      >
        {/* Navigation Buttons */}
        <Box
          display={"flex"}
          flexDirection={{
            xs: "row",
            sm: "row",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
          px={{
            xs: 2,
            sm: 5,
          }}
        >
          <IconButton
            onClick={() => {
              if (currentStep > 0) {
                setCurrentStep(currentStep - 1);
              }
            }}
            sx={{
              backgroundColor: "#ffffff1f",
              borderRadius: "50%",
              width: 50,
              height: 50,
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
          <Typography
            pt={2}
            variant="h2"
            sx={{
              fontSize: {
                xs: 22,
                sm: 30,
                md: 40,
              },
              fontWeight: "600",
              color: "#fff",
              textAlign: "center",
              flexGrow: 1,
            }}
          >
            Neden Kit-Tak İş Ortağı Olmalıyım?
          </Typography>
          <IconButton
            onClick={() => {
              if (currentStep < 3) {
                if (currentStep === 2) {
                  if (basvuruSart1 && basvuruSart2) {
                    setCurrentStep(currentStep + 1);
                  } else {
                    alert("Lütfen tüm şartları kabul edin.");
                    return;
                  }
                } else {
                  setCurrentStep(currentStep + 1);
                }
              }
            }}
            sx={{
              backgroundColor: "#ffffff1f",
              borderRadius: "50%",
              width: 50,
              height: 50,
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
        </Box>
        {/* Step Indicator */}
        <Typography
          py={2}
          variant="h5"
          sx={{
            color: "#ffffffb8",
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "#fff",
            }}
          >
            {currentStep + 1} / 4
          </span>{" "}
          Adım
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 2,
            px: {
              xs: 2,
              sm: 4,
            },
          }}
        >
          {[...Array(4)].map((_, index) => (
            <Box
              key={index}
              sx={{
                mx: 0.5,
                flexGrow: 1,
                height: 4,
                backgroundColor: currentStep >= index ? "#fff" : "#ffffff4c",
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            backgroundColor: "#F6F6F6",
            borderRadius: "8px",
            mt: 4,
            mx: {
              xs: 1,
              sm: 2,
              md: 4,
            },
            p: {
              xs: 2,
              sm: 3,
            },
          }}
        >
          {renderSteps}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default NedenKitTakOrtak;
