import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";

export interface NavMenuLinkItem {
  text: string;
  link: string;
}

export interface SocialLinkItem {
  icon: any;
  link: string;
}

export interface NavMenuProps {
  links: NavMenuLinkItem[];
  socialLinks: SocialLinkItem[];
  isMobile?: boolean;
  handleDrawerToggle?: () => void;
}

const NavMenu = ({
  links,
  socialLinks,
  isMobile,
  handleDrawerToggle,
}: NavMenuProps) => {
  const menuItems = links.map((item) => (
    <Button
      key={item.text}
      onClick={() => {
        window.location.href = item.link;
        if (handleDrawerToggle) handleDrawerToggle();
      }}
      sx={{
        color: isMobile ? "white" : "black",
        justifyContent: isMobile ? "flex-start" : "center",
        width: isMobile ? "100%" : "auto",
        textTransform: "none",
        px: isMobile ? 2 : 1,
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 16, md: 15 },
        }}
      >
        {item.text}
      </Typography>
    </Button>
  ));

  const socialIcons = socialLinks.map((item) => (
    <img
      key={item.link}
      style={{ cursor: "pointer", width: 22, height: 22 }}
      onClick={() => {
        window.open(item.link);
        if (handleDrawerToggle) handleDrawerToggle();
      }}
      src={item.icon}
      alt="Social Icon"
    />
  ));

  if (isMobile) {
    return (
      <Box
        sx={{
          width: 350,
        }}
      >
        <List
          sx={{
            p: 2,
          }}
        >
          {links.map((item) => (
            <ListItem
              components={{ Root: "li" }}
              key={item.text}
              onClick={() => {
                window.location.href = item.link;
                if (handleDrawerToggle) handleDrawerToggle();
              }}
            >
              <Typography
                py={2}
                sx={{
                  color: "white",
                  fontSize: 20,
                  textTransform: "none",
                }}
              >
                {item.text}
              </Typography>
            </ListItem>
          ))}
        </List>
        {/*  <Divider sx={{ backgroundColor: "gray", my: 2 }} /> */}

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              window.location.href = "/utts-montaj-basvuru";
              if (handleDrawerToggle) handleDrawerToggle();
            }}
            sx={{
              backgroundColor: "#FDC448",
              color: "#02021E",
              borderRadius: 20,
              width: "80%",
              py: 1.5,
              fontSize: 16,
              fontWeight: "medium",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#e0b33d",
              },
            }}
          >
            UTTS Montaj Başvurusu
          </Button>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              window.location.href = "/utts-kittak-is-ortagi";
              if (handleDrawerToggle) handleDrawerToggle();
            }}
            sx={{
              backgroundColor: "#fff",
              color: "#02021E",
              borderRadius: 20,
              width: "80%",
              py: 1.5,
              fontSize: 16,
              fontWeight: "medium",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#e0b33d",
              },
            }}
          >
            UTTS Bayilik Başvurusu
          </Button>
        </Box>

        {/*  <Box
          mt={7}
          sx={{
            display: "flex",
            gap: 4,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {socialIcons}
        </Box> */}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: { xs: 1, md: 2 },
        backgroundColor: "#b2bfc1",
        borderRadius: 40,
        px: 2,
        py: 1,
      }}
    >
      {menuItems}
      {/*   <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        {socialIcons}
      </Box> */}
    </Box>
  );
};

export default NavMenu;
