import {
  Box,
  Button,
  Typography,
  IconButton,
  Drawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import NavMenu from "./NavMenu/NavMenu";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#002B32",
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: { xs: 2, md: 4 },
        }}
      >
        <img
          onClick={() => (window.location.href = "/")}
          src={require("../../assets/img/logo.png")}
          width={isMobile ? 60 : 120}
          height={isMobile ? 60 : 120}
          alt="Logo"
          style={{ cursor: "pointer", marginTop: 10 }}
        />
        {/* Mobilde Hamburger Menü */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { xs: "block", md: "none" }, color: "white" }}
        >
          <MenuIcon />
        </IconButton>
        {/* Masaüstünde Navigasyon Menüsü */}

        {/*     <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
          <NavMenu
            links={[
              { text: "UTTS Kayıt Süreci", link: "/utts-kayit-sureci" },

              { text: "Montaj Başvurusu", link: "/utts-montaj-basvuru" },
            ]}
            socialLinks={[
              {
                icon: require("../../assets/img/instagram.png"),
                link: "https://www.instagram.com/utts.istanbul/",
              },
              {
                icon: require("../../assets/img/facebook.png"),
                link: "https://www.facebook.com/utts.istanbul",
              },
            ]}
          />
        </Box> */}

        <Box
          sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          gap={2}
        >
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            <Box
              sx={{
                border: "1px solid #36BFFA",
                borderRadius: 10,
              }}
            >
              <Button
                onClick={() => (window.location.href = "/utts-montaj-basvuru")}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                    fontWeight: "bold",
                    px: 2,
                    py: 1,
                    textTransform: "none",
                  }}
                >
                  UTTS Montaj Başvurusu
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            <Box
              sx={{
                border: "1px solid #36BFFA",
                borderRadius: 10,
              }}
            >
              <Button
                onClick={() =>
                  (window.location.href = "/utts-kittak-is-ortagi")
                }
              >
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                    fontWeight: "bold",
                    px: 2,
                    py: 1,
                    textTransform: "none",
                  }}
                >
                  UTTS Bayilik Başvurusu
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Mobilde Çekmece Menüsü */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            width: 350,
            backgroundColor: "#002B32",
            color: "white",
          },
        }}
      >
        <NavMenu
          links={[
            /* { text: "UTTS Nedir?", link: "/utts-nedir" },
            { text: "UTTS Kayıt Süreci", link: "/utts-kayit-sureci" },
            {
              text: "Sık Sorulan Sorular",
              link: "/utts-sık-sorulan-sorular",
            },
            { text: "Montaj Başvurusu", link: "/utts-montaj-basvuru" }, */
          ]}
          socialLinks={[
            {
              icon: require("../../assets/img/instagram.png"),
              link: "https://www.instagram.com/utts.istanbul/",
            },
            {
              icon: require("../../assets/img/facebook.png"),
              link: "https://www.facebook.com/utts.istanbul",
            },
          ]}
          isMobile
          handleDrawerToggle={handleDrawerToggle}
        />
      </Drawer>
    </Box>
  );
};

export default Header;
