// src/pages/BasvuruDetay.tsx

import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Alert,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  Button as MuiButton,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { IBasvuru, IBayiiBasvuru } from "./Admin"; // İlgili interface'leri import edin
import axios from "axios";

export const isIBayiiBasvuru = (
  basvuru: IBasvuru | IBayiiBasvuru | null
): basvuru is IBayiiBasvuru => {
  return (
    basvuru !== null && (basvuru as IBayiiBasvuru).yetkiliIsmi !== undefined
  );
};

const BasvuruDetay: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [basvuru, setBasvuru] = useState<IBasvuru | IBayiiBasvuru | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  // Kullanıcının doğrulanmış olup olmadığını kontrol edin
  useEffect(() => {
    const isAuthenticated =
      sessionStorage.getItem("isAuthenticated") === "true";
    const sessionExpiry = sessionStorage.getItem("sessionExpiry");
    let isSessionValid = false;

    if (isAuthenticated && sessionExpiry) {
      const expiryDate = new Date(sessionExpiry);
      if (new Date() < expiryDate) {
        isSessionValid = true;
      } else {
        // Oturum süresi dolmuş, oturumu temizleyin
        sessionStorage.removeItem("isAuthenticated");
        sessionStorage.removeItem("sessionExpiry");
      }
    }

    if (!isSessionValid) {
      // Doğrulanmamışsa Admin sayfasına yönlendirin
      navigate("/kittak-admin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchBasvuru = async () => {
      setLoading(true);
      setError("");
      try {
        let response;

        // Tür bilgisini route state'den al
        let type = location.state?.type;

        if (type === "Bayii") {
          // Bayii Başvurusu için API çağrısı
          response = await axios.get<IBayiiBasvuru>(
            `${process.env.REACT_APP_BACKEND_URL}/api/bayii-basvuru/basvuru/${id}`
          );
          setBasvuru(response.data);
        } else {
          // Montaj Başvurusu için API çağrısı
          response = await axios.get<IBasvuru>(
            `${process.env.REACT_APP_BACKEND_URL}/api/basvuru/${id}`
          );
          setBasvuru(response.data);
        }
      } catch (err: any) {
        console.error("Error fetching basvuru:", err);
        setError("Başvuru bulunamadı.");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBasvuru();
    } else {
      setError("Geçersiz Başvuru ID'si.");
      setLoading(false);
    }
  }, [id, location.state]);

  if (loading) {
    return (
      <Box>
        <Header />
        <Box sx={{ p: 4, display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
        <Footer />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Header />
        <Box sx={{ p: 4 }}>
          <Alert severity="error">{error}</Alert>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ mt: 2 }}
          >
            Geri Dön
          </Button>
        </Box>
        <Footer />
      </Box>
    );
  }

  // Türü belirlemek için type guard kullanın
  const isBayii = isIBayiiBasvuru(basvuru);

  return (
    <Box>
      <Header />
      <Box sx={{ mx: { xs: 2, sm: 6, md: 6 }, my: 4 }}>
        <Paper sx={{ p: 4 }}>
          <Typography variant="h5" gutterBottom>
            {isBayii ? "Bayii Başvuru Detayı" : "Montaj Başvuru Detayı"}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mt: 2 }}>
            {isBayii ? (
              <Grid container spacing={4}>
                {/* Yetkili Bilgileri */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Yetkili Bilgileri
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography>
                      <strong>Yetkili İsmi:</strong> {basvuru?.yetkiliIsmi}
                    </Typography>
                    <Typography>
                      <strong>Doğum Tarihi:</strong>{" "}
                      {new Date(basvuru?.dogumTarihi).toLocaleDateString()}
                    </Typography>
                    <Typography>
                      <strong>TC Kimlik Numarası:</strong> {basvuru?.tcKimlikNo}
                    </Typography>
                    <Typography>
                      <strong>Yetkili Mail Adresi:</strong>{" "}
                      {basvuru?.yetkiliMailAdresi}
                    </Typography>
                    <Typography>
                      <strong>Yetkili Telefon Numarası:</strong>{" "}
                      {basvuru?.yetkiliTelefonNo}
                    </Typography>
                  </Box>
                </Grid>

                {/* Firma Bilgileri */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Firma Bilgileri
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography>
                      <strong>Firma Adı:</strong> {basvuru?.firmaAdi}
                    </Typography>
                    <Typography>
                      <strong>Resmi Ünvan:</strong> {basvuru?.resmiUnvan}
                    </Typography>
                    <Typography>
                      <strong>Vergi Dairesi:</strong> {basvuru?.vergiDairesi}
                    </Typography>
                    <Typography>
                      <strong>Vergi No:</strong> {basvuru?.vergiNo}
                    </Typography>
                    <Typography>
                      <strong>Firma Web Sitesi:</strong>{" "}
                      {basvuru?.firmaWebSitesi || "Belirtilmemiş"}
                    </Typography>
                    <Typography>
                      <strong>E-Fatura Mukellefi:</strong>{" "}
                      {basvuru?.eFaturaMukellefi ? "Evet" : "Hayır"}
                    </Typography>
                  </Box>
                </Grid>

                {/* Montaj Noktası Bilgileri */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Montaj Noktası Bilgileri
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography>
                      <strong>Montaj Noktası Tabela İsmi:</strong>{" "}
                      {basvuru?.montajTabelaIsmi}
                    </Typography>
                    <Typography>
                      <strong>Montaj Vergi No:</strong> {basvuru?.montajVergiNo}
                    </Typography>
                    <Typography>
                      <strong>Montaj Vergi Dairesi:</strong>{" "}
                      {basvuru?.montajVergiDairesi}
                    </Typography>
                    <Typography>
                      <strong>Montaj İl:</strong> {basvuru?.montajIl}
                    </Typography>
                    <Typography>
                      <strong>Montaj İlçe:</strong> {basvuru?.montajIlce}
                    </Typography>
                    <Typography>
                      <strong>Montaj Mahalle:</strong> {basvuru?.montajMahalle}
                    </Typography>
                    <Typography>
                      <strong>Montaj Adres:</strong> {basvuru?.montajAdres}
                    </Typography>
                    <Typography>
                      <strong>Enlem:</strong> {basvuru?.enlem}
                    </Typography>
                    <Typography>
                      <strong>Boylam:</strong> {basvuru?.boylam}
                    </Typography>
                  </Box>
                </Grid>

                {/* İşletme Bilgileri */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    İşletme Bilgileri
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography>
                      <strong>İşletme Tipi:</strong> {basvuru?.isletmeTipi}
                    </Typography>
                    <Typography>
                      <strong>İşyeri Tipi:</strong> {basvuru?.isyeriTipi}
                    </Typography>
                    <Typography>
                      <strong>Toplam Çalışan Sayısı:</strong>{" "}
                      {basvuru?.toplamCalisanSayisi}
                    </Typography>
                    <Typography>
                      <strong>Montaj Çalışan Sayısı:</strong>{" "}
                      {basvuru?.montajCalisanSayisi}
                    </Typography>
                    <Typography>
                      <strong>Çalışma Günleri:</strong>{" "}
                      {basvuru?.calismaGunleri}
                    </Typography>
                    <Typography>
                      <strong>Toplam Araç Kapasitesi:</strong>{" "}
                      {basvuru?.toplamAracKapasitesi}
                    </Typography>
                    <Typography>
                      <strong>Araç Tipleri:</strong> {basvuru?.aracTipleri}
                    </Typography>
                    <Typography>
                      <strong>Mobil Montaj Hizmeti Sağlayabiliyor mu?:</strong>{" "}
                      {basvuru?.mobilMontajHizmeti}
                    </Typography>
                    <Typography>
                      <strong>İnternet Hizmeti Mevcut mu?:</strong>{" "}
                      {basvuru?.internetHizmeti}
                    </Typography>
                  </Box>
                </Grid>

                {/* Başvuru Bilgileri */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Başvuru Bilgileri
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography>
                      <strong>Başvuru Tipi:</strong> {basvuru?.basvuruTipi}
                    </Typography>
                  </Box>
                </Grid>

                {/* Yüklenen Dosyalar */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Yüklenen Dosyalar
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {"uploadedFiles" in basvuru &&
                    basvuru?.uploadedFiles.length > 0 ? (
                      <List>
                        {basvuru?.uploadedFiles.map((file, index) => (
                          <ListItem key={index} disableGutters>
                            <ListItemIcon>
                              {file.mimetype === "application/pdf" ? (
                                <PictureAsPdfIcon color="error" />
                              ) : (
                                <InsertDriveFileIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Link
                                  href={`${process.env.REACT_APP_BACKEND_URL}/${file.path}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  underline="hover"
                                >
                                  {file.originalname}
                                </Link>
                              }
                            />
                            <MuiButton
                              href={`${process.env.REACT_APP_BACKEND_URL}/${file.path}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              startIcon={<DownloadIcon />}
                            >
                              İndir
                            </MuiButton>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography>Yüklenen dosya bulunmamaktadır.</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={4}>
                {/* Montaj Başvuru Bilgileri */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Montaj Başvuru Bilgileri
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    <Typography>
                      <strong>Firma İsmi:</strong> {basvuru?.firmaIsmi}
                    </Typography>
                    <Typography>
                      <strong>Yetkili İsim Soyisim:</strong>{" "}
                      {basvuru?.yetkiliIsimSoyisim}
                    </Typography>
                    <Typography>
                      <strong>Telefon Numarası:</strong>{" "}
                      {basvuru?.yetkiliTelefon}
                    </Typography>
                    <Typography>
                      <strong>Taşıt Sayısı:</strong> {basvuru?.tasitSayisi}
                    </Typography>
                    <Typography>
                      <strong>Taşıt Tipi:</strong> {basvuru?.tasitTipi}
                    </Typography>
                    <Typography>
                      <strong>Vergi Dairesi:</strong> {basvuru?.vergiDairesi}
                    </Typography>
                    <Typography>
                      <strong>Vergi Numarası:</strong> {basvuru?.vergiNumarasi}
                    </Typography>
                    <Typography>
                      <strong>Mersis No:</strong> {basvuru?.mersisNo}
                    </Typography>
                    <Typography>
                      <strong>İl:</strong> {basvuru?.il}
                    </Typography>
                    <Typography>
                      <strong>İlçe:</strong> {basvuru?.ilce}
                    </Typography>
                    <Typography>
                      <strong>Adres:</strong> {basvuru?.adres}
                    </Typography>
                    <Typography>
                      <strong>Randevu Tarihi:</strong>{" "}
                      {new Date(
                        basvuru?.randevuTarihi ?? new Date()
                      ).toLocaleDateString()}
                    </Typography>
                    <Typography>
                      <strong>Randevu Saati:</strong> {basvuru?.randevuSaati}
                    </Typography>
                    <Typography>
                      <strong>Başvuru Tipi:</strong> {basvuru?.basvuruTipi}
                    </Typography>
                    {basvuru?.darphaneKodAldiMi ? (
                      <Typography>
                        <strong>Darphane Kod Alındı:</strong>
                        {basvuru?.darphaneKodAldiMi ? "Evet" : "Hayır"}
                      </Typography>
                    ) : (
                      <Typography>
                        <strong>Darphane Kod Alındı:</strong>
                        Bilinmiyor
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ mt: 4 }}
          >
            Geri Dön
          </Button>
        </Paper>
      </Box>
      <Footer />
    </Box>
  );
};

export default BasvuruDetay;
