// src/pages/Admin.tsx

import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Normal Başvuru Interface'i
export interface IBasvuru {
  _id: string;
  firmaIsmi: string;
  yetkiliIsimSoyisim: string;
  yetkiliTelefon: string;
  tasitSayisi: string;
  tasitTipi: string;
  vergiDairesi: string;
  vergiNumarasi: string;
  mersisNo: string;
  il: string;
  ilce: string;
  adres: string;
  randevuTarihi: Date;
  randevuSaati: string;
  basvuruTipi: string;
  darphaneKodAldiMi: boolean;
}

// Bayii Başvuru Interface'i
export interface IBayiiBasvuru {
  id: string;
  // Yetkili Bilgileri
  yetkiliIsmi: string;
  dogumTarihi: Date;
  tcKimlikNo: string;
  yetkiliMailAdresi: string;
  yetkiliTelefonNo: string;

  // Firma Bilgileri
  firmaAdi: string;
  resmiUnvan: string;
  vergiDairesi: string;
  vergiNo: string;
  firmaWebSitesi?: string;
  eFaturaMukellefi: boolean;

  // Firma Adres Bilgileri
  firmaIl: string;
  firmaIlce: string;
  postaKodu: string;
  adres: string;

  // Gerekli Bilgiler (Uploaded Files)
  uploadedFiles: IUploadedFile[];

  // Montaj Noktasına Ait Bilgiler
  montajTabelaIsmi: string;
  montajVergiNo: string;
  montajVergiDairesi: string;
  montajIl: string;
  montajIlce: string;
  montajMahalle: string;
  montajAdres: string;
  enlem?: number;
  boylam?: number;

  // İşletmeye ait bilgiler
  isletmeTipi: string;
  isyeriTipi: string;
  toplamCalisanSayisi: number;
  montajCalisanSayisi: number;
  calismaGunleri: string;
  toplamAracKapasitesi: number;
  aracTipleri: string;
  mobilMontajHizmeti: "Evet" | "Hayır";
  internetHizmeti: "Evet" | "Hayır";

  // Onaylar
  sartlariKabulEdiyorum: boolean;
  kvkkOnay: boolean;

  basvuruTipi: string;
}

// Uploaded File Interface'i
export interface IUploadedFile {
  filename: string;
  originalname: string;
  path: string;
  mimetype: string;
  size: number;
}

const Admin: React.FC = () => {
  // Auth statelerini tanımlayın
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [sessionExpiry, setSessionExpiry] = useState<Date | null>(null);
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  }>({
    username: "",
    password: "",
  });
  const [error, setError] = useState<string>("");

  // Başvuru türünü yönetmek için state
  const [tabValue, setTabValue] = useState<number>(0);

  // Başvuru verilerini yönetmek için state
  const [basvurular, setBasvurular] = useState<IBasvuru[]>([]);
  const [bayiiBasvurular, setBayiiBasvurular] = useState<IBayiiBasvuru[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>("");

  const navigate = useNavigate();

  // Bileşen yüklendiğinde session kontrolü yapın
  useEffect(() => {
    const storedAuth = sessionStorage.getItem("isAuthenticated");
    const storedExpiry = sessionStorage.getItem("sessionExpiry");

    if (storedAuth === "true" && storedExpiry) {
      const expiryDate = new Date(storedExpiry);
      if (new Date() < expiryDate) {
        setIsAuthenticated(true);
        setSessionExpiry(expiryDate);
      } else {
        // Session süresi dolmuş, verileri temizleyin
        sessionStorage.removeItem("isAuthenticated");
        sessionStorage.removeItem("sessionExpiry");
      }
    }
  }, []);

  // Session süresini yönetmek için efekt
  useEffect(() => {
    if (isAuthenticated && sessionExpiry) {
      const timeout = sessionExpiry.getTime() - new Date().getTime();
      if (timeout > 0) {
        const timer = setTimeout(() => {
          handleLogout();
        }, timeout);

        // Cleanup timeout on unmount or when dependencies change
        return () => clearTimeout(timer);
      } else {
        handleLogout();
      }
    }
  }, [isAuthenticated, sessionExpiry]);

  // Giriş formundaki değişiklikleri yönetin
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Giriş işlemini yönetin
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    // Gerçek bir kimlik doğrulama mantığı ile değiştirin
    const { username, password } = credentials;
    if (username === "kittakAdmin" && password === "uTtSKittak2024") {
      const expiry = new Date(new Date().getTime() + 30 * 60 * 1000); // 30 dakika
      sessionStorage.setItem("isAuthenticated", "true");
      sessionStorage.setItem("sessionExpiry", expiry.toISOString());
      setIsAuthenticated(true);
      setSessionExpiry(expiry);
      setError("");
    } else {
      setError("Geçersiz kullanıcı adı veya şifre.");
    }
  };

  // Çıkış işlemini yönetin
  const handleLogout = () => {
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("sessionExpiry");
    setIsAuthenticated(false);
    setSessionExpiry(null);
    setCredentials({ username: "", password: "" });
  };

  // Sekme değişimini yönetin
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Başvuruları API'den alın
  useEffect(() => {
    if (isAuthenticated) {
      const fetchBasvurular = async () => {
        setLoading(true);
        setFetchError("");
        try {
          // Normal Başvurular
          const basvuruResponse = await axios.get<IBasvuru[]>(
            `${process.env.REACT_APP_BACKEND_URL}/api/basvuru`
          );
          setBasvurular(basvuruResponse.data);

          // Bayii Başvurular
          const bayiiResponse = await axios.get<IBayiiBasvuru[]>(
            `${process.env.REACT_APP_BACKEND_URL}/api/bayii-basvuru/basvuru`
          );
          setBayiiBasvurular(bayiiResponse.data);
        } catch (err) {
          console.error("Error fetching basvurular:", err);
          setFetchError("Başvurular alınırken bir hata oluştu.");
        } finally {
          setLoading(false);
        }
      };

      fetchBasvurular();
    }
  }, [isAuthenticated]);

  // Tab başvurularını filtreleyin
  const filteredBasvurular = tabValue === 0 ? basvurular : bayiiBasvurular;

  // Tablo başlıklarını dinamik olarak belirleyin
  const getTableHeaders = () => {
    if (tabValue === 0) {
      return [
        "Randevu Tarihi",
        "Firma İsmi",
        "Yetkili İsim Soyisim",
        "Telefon Numarası",
      ];
    } else {
      return [
        "Yetkili İsmi",
        "Firma Adı",
        "Vergi Dairesi",
        "Vergi No",
        "Telefon Numarası",
        "İl",
        "İlçe",
        "Adres",
        // Diğer bayii başvuru alanları
      ];
    }
  };

  // Tablo satırına tıklandığında detay sayfasına yönlendirin
  const handleRowClick = (id: string, type: "Montaj" | "Bayii") => {
    navigate(`/kittak-admin/basvuru/${id}`, { state: { type } });
  };

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={4}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
        }}
      >
        <Box py={{ xs: 3, md: 5 }} px={2}>
          <Box
            sx={{
              background: "#F9FAFB",
              borderRadius: 10,
            }}
          >
            <Box
              sx={{
                backgroundColor: "#E8EBFF",
                p: 5,
                borderRadius: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "#002B32",
                }}
              >
                Kit-Tak Admin Panel
              </Typography>

              {/* Kimlik Doğrulama Bölümü */}
              {isAuthenticated ? (
                <>
                  {/* Admin İçeriği */}
                  <Box mt={4}>
                    <Typography variant="h6" color="#002B32">
                      Yönetici Paneline Hoşgeldiniz!
                    </Typography>
                    {/* Başvurular Sekmeleri */}
                    <Box sx={{ width: "100%", mt: 4 }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Başvurular Sekmeleri"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        <Tab label="Montaj Başvurusu" />
                        <Tab label="Bayii Başvurusu" />
                      </Tabs>
                      <TabPanel value={tabValue} index={0}>
                        {loading ? (
                          <Box display="flex" justifyContent="center" mt={2}>
                            <CircularProgress />
                          </Box>
                        ) : fetchError ? (
                          <Alert severity="error">{fetchError}</Alert>
                        ) : (
                          <BasvuruTable
                            basvurular={filteredBasvurular as IBasvuru[]}
                            onRowClick={(id) => handleRowClick(id, "Montaj")}
                            tabValue={tabValue}
                          />
                        )}
                      </TabPanel>
                      <TabPanel value={tabValue} index={1}>
                        {loading ? (
                          <Box display="flex" justifyContent="center" mt={2}>
                            <CircularProgress />
                          </Box>
                        ) : fetchError ? (
                          <Alert severity="error">{fetchError}</Alert>
                        ) : (
                          <BasvuruTable
                            basvurular={filteredBasvurular as IBayiiBasvuru[]}
                            onRowClick={(id) => handleRowClick(id, "Bayii")}
                            tabValue={tabValue}
                          />
                        )}
                      </TabPanel>
                    </Box>
                    {/* Çıkış Butonu */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLogout}
                      sx={{ mt: 2 }}
                    >
                      Çıkış Yap
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  {/* Giriş Formu */}
                  <Box
                    component="form"
                    onSubmit={handleLogin}
                    sx={{ mt: 4, maxWidth: 400 }}
                  >
                    <Typography variant="h6" color="#002B32" gutterBottom>
                      Yönetici Girişi
                    </Typography>
                    {error && (
                      <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                      </Alert>
                    )}
                    <TextField
                      fullWidth
                      label="Kullanıcı Adı"
                      name="username"
                      value={credentials.username}
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Şifre"
                      name="password"
                      type="password"
                      value={credentials.password}
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Giriş Yap
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

// TabPanel Bileşeni
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`basvuru-tabpanel-${index}`}
      aria-labelledby={`basvuru-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
};

// Başvuru Tablosu Bileşeni
interface BasvuruTableProps {
  basvurular: IBasvuru[] | IBayiiBasvuru[];
  onRowClick: (id: string) => void;
  tabValue: number;
}

const BasvuruTable: React.FC<BasvuruTableProps> = ({
  basvurular,
  onRowClick,
  tabValue,
}) => {
  // Montaj Başvurusu ise IBasvuru, Bayii Başvurusu ise IBayiiBasvuru
  const isBayii = tabValue === 1;

  // Tablo başlıklarını dinamik olarak belirleyin
  const tableHeaders = isBayii
    ? [
        "Yetkili İsmi",
        "Firma Adı",
        "Vergi Dairesi",
        "Vergi No",
        "Telefon Numarası",
        "İl",
        "İlçe",
        "Adres",
        // Diğer bayii başvuru alanları ekleyebilirsiniz
      ]
    : [
        "Randevu Tarihi",
        "Firma İsmi",
        "Yetkili İsim Soyisim",
        "Telefon Numarası",
      ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="başvuru tablosu">
        <TableHead>
          <TableRow>
            {tableHeaders.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {basvurular.map((basvuru: any) => (
            <TableRow
              key={basvuru._id || basvuru.id} // Mongoose'un _id alanını veya bayii için id
              hover
              sx={{ cursor: "pointer" }}
              onClick={() => onRowClick(basvuru._id || basvuru.id)}
            >
              {isBayii ? (
                <>
                  <TableCell>{basvuru.yetkiliIsmi}</TableCell>
                  <TableCell>{basvuru.firmaAdi}</TableCell>
                  <TableCell>{basvuru.vergiDairesi}</TableCell>
                  <TableCell>{basvuru.vergiNo}</TableCell>
                  <TableCell>{basvuru.yetkiliTelefonNo}</TableCell>
                  <TableCell>{basvuru.firmaIl}</TableCell>
                  <TableCell>{basvuru.firmaIlce}</TableCell>
                  <TableCell>{basvuru.adres}</TableCell>
                  {/* Diğer bayii başvuru alanlarını ekleyin */}
                </>
              ) : (
                <>
                  <TableCell>
                    {new Date(basvuru.randevuTarihi).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{basvuru.firmaIsmi}</TableCell>
                  <TableCell>{basvuru.yetkiliIsimSoyisim}</TableCell>
                  <TableCell>{basvuru.yetkiliTelefon}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Admin;
